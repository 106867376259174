import OpenAI from 'openai';

export default defineNuxtPlugin({
  name: 'civo-ai',
  dependsOn: ['supabase'],
  parallel: true,
  setup() {
    const config = useRuntimeConfig();
    return {
      provide: {
        civoai: (apikey: string) => {
          const civoai = new OpenAI({
            apiKey: apikey,
            baseURL: config.public.openaiUrl,
            dangerouslyAllowBrowser: true,
          });
          return civoai;
        },
      },
    };
  },
});
