import { default as _91_46_46_46id_93NFpMLXxvuxMeta } from "/app/pages/chat/[...id].vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as recentsVwtBV9cbOIMeta } from "/app/pages/recents.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as accountFbmhd9M064Meta } from "/app/pages/settings/account.vue?macro=true";
import { default as customisationfVKYd9yAGeMeta } from "/app/pages/settings/customisation.vue?macro=true";
import { default as privacypBEtlj6mICMeta } from "/app/pages/settings/privacy.vue?macro=true";
import { default as securityhN1Moe7b24Meta } from "/app/pages/settings/security.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as signinUhHmpbjN2pMeta } from "/app/pages/signin.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "chat-id",
    path: "/chat/:id(.*)*",
    component: () => import("/app/pages/chat/[...id].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password1UbT4qDpFeMeta || {},
    component: () => import("/app/pages/forgot-password.vue")
  },
  {
    name: "recents",
    path: "/recents",
    component: () => import("/app/pages/recents.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/app/pages/settings.vue"),
    children: [
  {
    name: "settings-account",
    path: "account",
    component: () => import("/app/pages/settings/account.vue")
  },
  {
    name: "settings-customisation",
    path: "customisation",
    component: () => import("/app/pages/settings/customisation.vue")
  },
  {
    name: "settings-privacy",
    path: "privacy",
    component: () => import("/app/pages/settings/privacy.vue")
  },
  {
    name: "settings-security",
    path: "security",
    component: () => import("/app/pages/settings/security.vue")
  }
]
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinUhHmpbjN2pMeta || {},
    component: () => import("/app/pages/signin.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupezBbZGXKU6Meta || {},
    component: () => import("/app/pages/signup.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/",
    component: component_45stubnO9MU04yTU
  }
]